import React, { lazy } from "react";

import {
  Route,
  useParams,
  Outlet,
  Routes,
  useNavigate,
} from "react-router-dom";
import { RoutePaths } from "./route-path";

const PrivacyPolicyPage = lazy(() => import("../pages/privacy-policy-page"));
const RefundAndCancellationPage = lazy(
  () => import("../pages/refund-and-cancellation-page")
);
const SelectStorePage = lazy(() => import("../pages/select-store-page"));
const TermsAndConditionsPage = lazy(
  () => import("../pages/terms-and-conditions-page")
);
const StoreClosed = lazy(() => import("../components/app/store-closed"));

const HomePage = lazy(() => import("../pages/home-page"));

const CategoriesPage = lazy(() => import("../pages/categories-page"));
const CheckOutDetailPage = lazy(
  () => import("../pages/check-out-details-page")
);
const WebCartPage = lazy(() => import("../pages/web-cart-page"));
const WebAccountPage = lazy(() => import("../pages/web-account"));
const WebCheckout = lazy(() => import("../pages/web-checkout"));
const WebDeliveryAddress = lazy(() => import("../pages/web-delivery-address"));

const ProductListPage = lazy(() => import("../pages/product-list-page"));
const ProductDetailPage = lazy(() => import("../pages/product-detail-page"));

const LoginPage = lazy(() => import("../pages/login-page"));
const SignUpPage = lazy(() => import("../pages/signup-page"));
const OrdersPage = lazy(() => import("../pages/orders-page"));
const OrderDetailPage = lazy(() => import("../pages/order-detail-page"));
const OrderSummaryPage = lazy(() => import("../pages/order-summary-page"));

const MobileVerificationPage = lazy(
  () => import("../pages/mobile-verification-page")
);

const AddressPage = lazy(() => import("../pages/address-page"));

const CartPage = lazy(() => import("../pages/cart-page"));
const ProfilePage = lazy(() => import("../pages/profile-page"));

const PremiumRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path={RoutePaths.NotFound} element={<StoreClosed />} />
      <Route path={RoutePaths.Home} element={<HomePage />} />
      <Route path={RoutePaths.Address} element={<AddressPage />} />
      <Route path={RoutePaths.Cart} element={<CartPage />} />
      <Route path={RoutePaths.Categories} element={<CategoriesPage />} />
      <Route
        path={RoutePaths.CheckoutDetail}
        element={<CheckOutDetailPage />}
      />
      <Route path={RoutePaths.WebCart} element={<WebCartPage />} />
      <Route path={RoutePaths.WebAccount} element={<WebAccountPage />} />
      <Route path={RoutePaths.WebCheckout} element={<WebCheckout />} />
      <Route
        path={RoutePaths.WebDeliveryAddress}
        element={<WebDeliveryAddress />}
      />
      <Route path={RoutePaths.Login} element={<LoginPage />} />
      <Route
        path={RoutePaths.VerifyMobile}
        element={<MobileVerificationPage />}
      />
      <Route path={RoutePaths.Orders} element={<OrdersPage />} />
      <Route path={RoutePaths.OrderDetails} element={<OrderDetailPage />} />
      <Route path={RoutePaths.SelectStore} element={<SelectStorePage />} />
      <Route path={RoutePaths.OrderSummary} element={<OrderSummaryPage />} />
      <Route path={RoutePaths.Products} element={<ProductListPage />} />
      <Route path={RoutePaths.ProductDetails} element={<ProductDetailPage />} />
      <Route path={RoutePaths.Profile} element={<ProfilePage />} />

      <Route path={RoutePaths.SignUp} element={<CheckOutDetailPage />} />

      <Route
        path={RoutePaths.RefundAndCancellation}
        element={<RefundAndCancellationPage />}
      />
      <Route
        path={RoutePaths.TermsAndConditions}
        element={<TermsAndConditionsPage />}
      />
      <Route path={RoutePaths.PrivacyPolicy} element={<PrivacyPolicyPage />} />
    </Routes>
  );
};

export default PremiumRoutes;
