import RocketBuyLogo from "../../../assets/images/rocketbuy-logo.svg";
import { FC, useEffect, useState } from "react";
import { StoreInfo } from "../../../models/StoreInfo";
import { getStores } from "../../../utils/store.utils";
import { Option, isSome, getOrElse, fromNullable } from "fp-ts/Option";
import { RF_SELECTED_STORE } from "../../../constants/storage.constant";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../loader/loader";
import StoreList from "../store-list/store-list";
import { routePath } from "../../../utils/route.utils";

type TSelectStorePopupProps = {
  showCross?: boolean;
  showModal?: boolean;
};
const SelectStorePopup: FC<TSelectStorePopupProps> = ({
  showCross,
  showModal,
}) => {
  const navigate = useNavigate();
  const { merchantCode } = useParams();
  const [loading, setLoading] = useState(false);
  const [stores, setStores] = useState<StoreInfo[]>([]);
  const [multiMerchant, setMultiMerchant] = useState(false);

  useEffect(() => {
    const strs: Option<StoreInfo[]> = getStores();
    if (isSome(strs)) {
      setStores(strs.value);
      if (strs.value.length) {
        setMultiMerchant(true);
      } else {
        navigate(routePath(merchantCode, `/home`), { replace: true });
      }
    }
  }, []);

  const handleStoreCross = () => {
    //
  };

  const handleSelectStore = (store: StoreInfo) => {
    localStorage.setItem(RF_SELECTED_STORE, JSON.stringify(store));
    setMultiMerchant(false);
    navigate(routePath(merchantCode, `/home`), { replace: true });
  };

  if (loading) {
    return <Loader />;
  }

  return (
    // ==================tailwind modal starts here==========================
    <>
      {multiMerchant && (
        <div
          id="modal"
          className="fixed top-0 left-0 w-screen  h-screen flex items-center justify-center bg-black bg-opacity-30 z-50 transform scale-100 transition-transform duration-300"
        >
          <div
            id="modal-container1"
            className="bg-white w-10/12  lg:w-5/12 h-128 overflow-x-auto rounded pt-0 p-4"
          >
            <div className="content-container text-center mb-3 ">
              <div className="pt-4 lg:pt-0 w-9/12 lg:w-5/12 lg:ml--5  lg:px-4  fixed">
                <div className="bg-white w-full rounded-t-md">
                  <div className="store-svg pt-4">
                    <img
                      className="w-14 h-14 mx-auto"
                      src={RocketBuyLogo}
                      alt="store"
                    />
                  </div>
                  <h1 className="text-lg mt-2 font-bold w-8/12 lg:w-10/12 mx-auto text-gray-600">
                    Select the store you want to shop from!
                  </h1>
                </div>
                {showCross && (
                  <button
                    onClick={handleStoreCross}
                    id="closebutton"
                    type="button"
                    className={`absolute top-1  -right-1 focus:outline-none`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-7 w-7 text-gray-500"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                )}
              </div>
              <div className="h-36 bg-white"></div>
              <div className="address-container  my-3">
                <StoreList
                  stores={fromNullable(stores)}
                  onStoreSelect={handleSelectStore}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
    // <!--=======================tailwind modal ends here==============================-->
  );
};
export default SelectStorePopup;
