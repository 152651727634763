import * as React from "react";
import { useEffect } from "react";

import { ProductInfo } from "../../models/ProductInfo";

type ProductListState = {
  loading: boolean;
  products: ProductInfo[];
  error?: any;
};
type ProductContextState = {
  productList: ProductListState;
  listProductsRequest: () => void;
  listProductsSuccess: (products: ProductInfo[]) => void;
  listProductsFail: (error: any) => void;
  selectedProductId: string;
  selectProduct: (productId: string) => void;
};

type ProductAction =
  | {
      type: "PRODUCT_LIST_REQUEST";
    }
  | {
      type: "PRODUCT_LIST_SUCCESS";
      payload: ProductInfo[];
    }
  | {
      type: "PRODUCT_LIST_FAIL";
      payload: any;
    }
  | {
      type: "SELECT_PRODUCT";
      payload: string;
    };

type ProductProviderProps = { children: React.ReactNode };

const initialProductState: ProductContextState = {
  productList: { loading: false, products: [] },
  listProductsRequest: () => {},
  listProductsSuccess: (products: ProductInfo[]) => {},
  listProductsFail: (error: any) => {},
  selectedProductId: "",
  selectProduct: (productId: string) => {},
};

const ProductStateContext =
  React.createContext<ProductContextState>(initialProductState);

ProductStateContext.displayName = "ProductContext";

function productContextReducer(
  state: ProductContextState = initialProductState,
  action: ProductAction
) {
  switch (action.type) {
    case "PRODUCT_LIST_REQUEST": {
      return {
        ...state,
        productList: { loading: true, products: [] }, //loading: action.payload,
      };
    }
    case "PRODUCT_LIST_SUCCESS": {
      return {
        ...state,
        productList: { loading: false, products: action.payload },
      };
    }
    case "PRODUCT_LIST_FAIL": {
      return {
        ...state,
        productList: { loading: false, products: [], error: action.payload },
      };
    }
    case "SELECT_PRODUCT": {
      return {
        ...state,
        selectedProductId: action.payload,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action}`);
    }
  }
}

function ProductContextProvider({ children }: ProductProviderProps) {
  const [state, dispatch] = React.useReducer(
    productContextReducer,
    initialProductState
  );

  useEffect(() => {
    const run = async () => {};
    run();
  }, []);

  const listProductsRequest = () => dispatch({ type: "PRODUCT_LIST_REQUEST" });

  const listProductsSuccess = (products: ProductInfo[]) =>
    dispatch({ type: "PRODUCT_LIST_SUCCESS", payload: products });

  const listProductsFail = (error: any) =>
    dispatch({ type: "PRODUCT_LIST_FAIL", payload: error });

  const selectProduct = (productId: string) => {
    dispatch({ type: "SELECT_PRODUCT", payload: productId });
  };

  const values = React.useMemo(
    () => ({
      ...state,
      listProductsRequest,
      listProductsSuccess,
      listProductsFail,
      selectProduct,
    }),
    [state]
  );

  return (
    <ProductStateContext.Provider value={{ ...values }}>
      {children}
    </ProductStateContext.Provider>
  );
}

function useProduct() {
  const context = React.useContext(ProductStateContext);
  if (context === undefined) {
    throw new Error("useHome must be used within a HomeContextProvider");
  }
  return context;
}
export { ProductContextProvider, useProduct };
