import { CancelTxnApiRequest } from "../models/CancelTxnApiRequest";
import { CreateOrderApiResponse } from "../models/CreateOrderApiResponse";
import { GetAllUserAddressResponse } from "../models/GetAllUserAddressResponse";
import { GetStoresApiResponse } from "../models/GetStoresApiResponse";
import { RocketbuyCustomerCreateOrderApiRequest } from "../models/RocketbuyCustomerCreateOrderApiRequest";
import { RocketbuyCustomerSignupApiRequest } from "../models/RocketbuyCustomerSignupApiRequest";
import { RocketbuyFileUploadApiResponse } from "../models/RocketbuyFileUploadApiResponse";
import { RocketbuyGetAllCategoriesApiResponse } from "../models/RocketbuyGetAllCategoriesApiResponse";
import { RocketbuyGetAllOrderApiResponse } from "../models/RocketbuyGetAllOrderApiResponse";
import { RocketbuyGetAllProductApiResponse } from "../models/RocketbuyGetAllProductApiResponse";
import { RocketbuyGetCartApiRequest } from "../models/RocketbuyGetCartApiRequest";
import { RocketbuyGetCartResponse } from "../models/RocketbuyGetCartResponse";
import { RocketbuyGetFulfilmentDetailApiRespone } from "../models/RocketbuyGetFulfilmentDetailApiRespone";
import { RocketbuyGetHomeDataResponse } from "../models/RocketbuyGetHomeDataResponse";
import { RocketbuyGetOrderStagesResponse } from "../models/RocketbuyGetOrderStagesResponse";
import { RocketbuyGetOrderSummaryApiRequest } from "../models/RocketbuyGetOrderSummaryApiRequest";
import { RocketbuyGetOrderSummaryApiResponse } from "../models/RocketbuyGetOrderSummaryApiResponse";
import { RocketbuyGetProductDetailApiResponse } from "../models/RocketbuyGetProductDetailApiResponse";
import { RocketbuyInitPayResponse } from "../models/RocketbuyInitPayResponse";
import { RocketbuyMyProfileResponse } from "../models/RocketbuyMyProfileResponse";
import { RocketbuyOrderDetailApiResponse } from "../models/RocketbuyOrderDetailApiResponse";
import { RocketbuyOrderDraftResponse } from "../models/RocketbuyOrderDraftResponse";
import { RocketbuyProcessPayRequest } from "../models/RocketbuyProcessPayRequest";
import { RocketbuyProcessPayResponse } from "../models/RocketbuyProcessPayResponse";
import { RocketbuyResetPasswordApiRequest } from "../models/RocketbuyResetPasswordApiRequest";
import { RocketbuyResetPasswordApiResponse } from "../models/RocketbuyResetPasswordApiResponse";
import { RocketbuySendOtpApiRequest } from "../models/RocketbuySendOtpApiRequest";
import { RocketbuySendOtpApiResponse } from "../models/RocketbuySendOtpApiResponse";
import { RocketbuySignupResponse } from "../models/RocketbuySignupResponse";
import { RocketbuyStoreKeyResponse } from "../models/RocketbuyStoreKeyResponse";
import { RocketbuyValidateCouponPromoRequest } from "../models/RocketbuyValidateCouponPromoRequest";
import { RocketbuyValidateCouponPromoResponse } from "../models/RocketbuyValidateCouponPromoResponse";
import { RocketbuyVerifyMobileApiRequest } from "../models/RocketbuyVerifyMobileApiRequest";
import { RocketbuyVerifyMobileApiResponse } from "../models/RocketbuyVerifyMobileApiResponse";
import { RocketflowBaseResponse } from "../models/RocketflowBaseResponse";
import { TrackiExecuteUpdateApiRequest } from "../models/TrackiExecuteUpdateApiRequest";
import { TrackiExecuteUpdateApiResponse } from "../models/TrackiExecuteUpdateApiResponse";
import { UserCreateNewAddressApiRequest } from "../models/UserCreateNewAddressApiRequest";
import { UserUpdateAddressApiRequest } from "../models/UserUpdateAddressApiRequest";
import { HttpClient } from "./http-client";

type TQueryParam = {
  key: string;
  value: string | string[] | boolean | number | Date | undefined;
};

const baseUrl =
  process.env.NODE_ENV !== "production"
    ? `${process.env.REACT_APP_API_URL || ""}`
    : `${process.env.REACT_APP_API_URL || ""}/rbapi`;

export class RocketBuyCustomerService extends HttpClient {
  public constructor() {
    super(`${baseUrl}`);
  }

  /**
   *
   * @summary Api to get all available categories in store
   * @param offset -
   * @param limit -
   * @param loadBy -
   */
  public categoriesUsingGET = async (
    offset?: number,
    limit?: number,
    loadBy?: string,
    customHeaders: Object = {}
  ) => {
    try {
      const path = "/secure/rb/customer/categories".replace(/^\//, "");
      const allQueryParams: TQueryParam[] = [
        {
          key: "offset",
          value: offset,
        },

        {
          key: "limit",
          value: limit,
        },

        {
          key: "loadBy",
          value: loadBy,
        },
      ];
      const queryParams = allQueryParams
        .map((param) => `${param.key}=${param.value}`)
        .join("&");

      const url = queryParams === "" ? path : path.concat(`?${queryParams}`);
      let headers = customHeaders;
      const response =
        await this.instance.get<RocketbuyGetAllCategoriesApiResponse>(url, {
          headers: { ...headers },
        });

      return response;
    } catch (err) {
      console.log("Error categoriesUsingGET ]", err);
      throw err;
    }
  };
  /**
   *
   * @summary Api to create new user address
   * @param request - request
   */
  public createAddressUsingPOST = async (
    request: UserCreateNewAddressApiRequest,
    customHeaders: Object = {}
  ) => {
    try {
      const path = "/secure/rb/customer/address/create".replace(/^\//, "");
      const allQueryParams: TQueryParam[] = [];
      const queryParams = allQueryParams
        .map((param) => `${param.key}=${param.value}`)
        .join("&");

      const url = queryParams === "" ? path : path.concat(`?${queryParams}`);
      let headers = customHeaders;
      const response = await this.instance.post<RocketflowBaseResponse>(
        url,
        request,
        {
          headers: { ...headers },
        }
      );

      return response;
    } catch (err) {
      console.log("Error createAddressUsingPOST ]", err);
      throw err;
    }
  };
  /**
   *
   * @summary Api to configure business
   * @param request - request
   */
  public createOrderUsingPOST = async (
    request: RocketbuyCustomerCreateOrderApiRequest,
    customHeaders: Object = {}
  ) => {
    try {
      const path = "/secure/rb/customer/order/create".replace(/^\//, "");
      const allQueryParams: TQueryParam[] = [];
      const queryParams = allQueryParams
        .map((param) => `${param.key}=${param.value}`)
        .join("&");

      const url = queryParams === "" ? path : path.concat(`?${queryParams}`);
      let headers = customHeaders;
      const response = await this.instance.post<CreateOrderApiResponse>(
        url,
        request,
        {
          headers: { ...headers },
        }
      );

      return response;
    } catch (err) {
      console.log("Error createOrderUsingPOST ]", err);
      throw err;
    }
  };
  /**
   *
   * @summary Api to delete user address
   * @param placeId -
   */
  public deleteAddressUsingDELETE = async (
    placeId?: string,
    customHeaders: Object = {}
  ) => {
    try {
      const path = "/secure/rb/customer/address/delete".replace(/^\//, "");
      const allQueryParams: TQueryParam[] = [
        {
          key: "placeId",
          value: placeId,
        },
      ];
      const queryParams = allQueryParams
        .map((param) => `${param.key}=${param.value}`)
        .join("&");

      const url = queryParams === "" ? path : path.concat(`?${queryParams}`);
      let headers = customHeaders;
      const response = await this.instance.delete<RocketflowBaseResponse>(url, {
        headers: { ...headers },
      });

      return response;
    } catch (err) {
      console.log("Error deleteAddressUsingDELETE ]", err);
      throw err;
    }
  };
  /**
   *
   * @summary Api to delete order
   * @param orderId -
   */
  public deleteOrderUsingDELETE = async (
    orderId?: string,
    customHeaders: Object = {}
  ) => {
    try {
      const path = "/secure/rb/customer/order/delete".replace(/^\//, "");
      const allQueryParams: TQueryParam[] = [
        {
          key: "orderId",
          value: orderId,
        },
      ];
      const queryParams = allQueryParams
        .map((param) => `${param.key}=${param.value}`)
        .join("&");

      const url = queryParams === "" ? path : path.concat(`?${queryParams}`);
      let headers = customHeaders;
      const response = await this.instance.delete<RocketflowBaseResponse>(url, {
        headers: { ...headers },
      });

      return response;
    } catch (err) {
      console.log("Error deleteOrderUsingDELETE ]", err);
      throw err;
    }
  };
  /**
   *
   * @summary Api to update task stage
   * @param taskRequest - taskRequest
   */
  public executeUpdateUsingPOST2 = async (
    taskRequest: TrackiExecuteUpdateApiRequest,
    customHeaders: Object = {}
  ) => {
    try {
      const path = "/secure/rb/customer/order/executeUpdate".replace(/^\//, "");
      const allQueryParams: TQueryParam[] = [];
      const queryParams = allQueryParams
        .map((param) => `${param.key}=${param.value}`)
        .join("&");

      const url = queryParams === "" ? path : path.concat(`?${queryParams}`);
      let headers = customHeaders;
      const response = await this.instance.post<TrackiExecuteUpdateApiResponse>(
        url,
        taskRequest,
        {
          headers: { ...headers },
        }
      );

      return response;
    } catch (err) {
      console.log("Error executeUpdateUsingPOST2 ]", err);
      throw err;
    }
  };
  /**
   *
   * @summary Api to get all user address
   */
  public getAllUserAddressUsingGET = async (customHeaders: Object = {}) => {
    try {
      const path = "/secure/rb/customer/address/get".replace(/^\//, "");
      const allQueryParams: TQueryParam[] = [];
      const queryParams = allQueryParams
        .map((param) => `${param.key}=${param.value}`)
        .join("&");

      const url = queryParams === "" ? path : path.concat(`?${queryParams}`);
      let headers = customHeaders;
      const response = await this.instance.get<GetAllUserAddressResponse>(url, {
        headers: { ...headers },
      });

      return response;
    } catch (err) {
      console.log("Error getAllUserAddressUsingGET ]", err);
      throw err;
    }
  };
  /**
   *
   * @summary Api to get cart detail
   * @param request - request
   */
  public getCartUsingPOST = async (
    request: RocketbuyGetCartApiRequest,
    customHeaders: Object = {}
  ) => {
    try {
      const path = "/secure/rb/customer/mycart".replace(/^\//, "");
      const allQueryParams: TQueryParam[] = [];
      const queryParams = allQueryParams
        .map((param) => `${param.key}=${param.value}`)
        .join("&");

      const url = queryParams === "" ? path : path.concat(`?${queryParams}`);
      let headers = customHeaders;
      const response = await this.instance.post<RocketbuyGetCartResponse>(
        url,
        request,
        {
          headers: { ...headers },
        }
      );

      return response;
    } catch (err) {
      console.log("Error getCartUsingPOST ]", err);
      throw err;
    }
  };
  /**
   *
   * @summary Api to get fulfilment settings
   */
  public getFulfilmentInfoUsingGET = async (customHeaders: Object = {}) => {
    try {
      const path = "/secure/rb/customer/store/fulfillment".replace(/^\//, "");
      const allQueryParams: TQueryParam[] = [];
      const queryParams = allQueryParams
        .map((param) => `${param.key}=${param.value}`)
        .join("&");

      const url = queryParams === "" ? path : path.concat(`?${queryParams}`);
      let headers = customHeaders;
      const response =
        await this.instance.get<RocketbuyGetFulfilmentDetailApiRespone>(url, {
          headers: { ...headers },
        });

      return response;
    } catch (err) {
      console.log("Error getFulfilmentInfoUsingGET ]", err);
      throw err;
    }
  };
  /**
   *
   * @summary Api to get home data
   */
  public getHomeDataUsingGET = async (customHeaders: Object = {}) => {
    try {
      const path = "/secure/rb/customer/home".replace(/^\//, "");
      const allQueryParams: TQueryParam[] = [];
      const queryParams = allQueryParams
        .map((param) => `${param.key}=${param.value}`)
        .join("&");

      const url = queryParams === "" ? path : path.concat(`?${queryParams}`);
      let headers = customHeaders;
      const response = await this.instance.get<RocketbuyGetHomeDataResponse>(
        url,
        {
          headers: { ...headers },
        }
      );

      return response;
    } catch (err) {
      console.log("Error getHomeDataUsingGET ]", err);
      throw err;
    }
  };
  /**
   *
   * @summary Api to get my profile info
   */
  public getMyProfileInfoUsingGET = async (customHeaders: Object = {}) => {
    try {
      const path = "/secure/rb/customer/myProfile".replace(/^\//, "");
      const allQueryParams: TQueryParam[] = [];
      const queryParams = allQueryParams
        .map((param) => `${param.key}=${param.value}`)
        .join("&");

      const url = queryParams === "" ? path : path.concat(`?${queryParams}`);
      let headers = customHeaders;
      const response = await this.instance.get<RocketbuyMyProfileResponse>(
        url,
        {
          headers: { ...headers },
        }
      );

      return response;
    } catch (err) {
      console.log("Error getMyProfileInfoUsingGET ]", err);
      throw err;
    }
  };
  /**
   *
   * @summary Api to get all orders,user id field is not required in this api
   * @param stageId -
   * @param from -
   * @param to -
   */
  public getOdersUsingGET = async (
    stageId?: string,
    from?: number,
    to?: number,
    customHeaders: Object = {}
  ) => {
    try {
      const path = "/secure/rb/customer/order/get".replace(/^\//, "");
      const allQueryParams: TQueryParam[] = [
        {
          key: "stageId",
          value: stageId,
        },

        {
          key: "from",
          value: from,
        },

        {
          key: "to",
          value: to,
        },
      ];
      const queryParams = allQueryParams
        .map((param) => `${param.key}=${param.value}`)
        .join("&");

      const url = queryParams === "" ? path : path.concat(`?${queryParams}`);
      let headers = customHeaders;
      const response = await this.instance.get<RocketbuyGetAllOrderApiResponse>(
        url,
        {
          headers: { ...headers },
        }
      );

      return response;
    } catch (err) {
      console.log("Error getOdersUsingGET ]", err);
      throw err;
    }
  };
  /**
   *
   * @summary Api to get order summary
   * @param draftId - draftId
   */
  public getOrderDraftIdUsingGET = async (
    draftId: string,
    customHeaders: Object = {}
  ) => {
    try {
      const path = "/secure/rb/customer/order/draft".replace(/^\//, "");
      const allQueryParams: TQueryParam[] = [
        {
          key: "draftId",
          value: draftId,
        },
      ];
      const queryParams = allQueryParams
        .map((param) => `${param.key}=${param.value}`)
        .join("&");

      const url = queryParams === "" ? path : path.concat(`?${queryParams}`);
      let headers = customHeaders;
      const response = await this.instance.get<RocketbuyOrderDraftResponse>(
        url,
        {
          headers: { ...headers },
        }
      );

      return response;
    } catch (err) {
      console.log("Error getOrderDraftIdUsingGET ]", err);
      throw err;
    }
  };
  /**
   *
   * @summary Api to get all order stages
   */
  public getOrderStagesUsingGET = async (customHeaders: Object = {}) => {
    try {
      const path = "/secure/rb/customer/order/stages".replace(/^\//, "");
      const allQueryParams: TQueryParam[] = [];
      const queryParams = allQueryParams
        .map((param) => `${param.key}=${param.value}`)
        .join("&");

      const url = queryParams === "" ? path : path.concat(`?${queryParams}`);
      let headers = customHeaders;
      const response = await this.instance.get<RocketbuyGetOrderStagesResponse>(
        url,
        {
          headers: { ...headers },
        }
      );

      return response;
    } catch (err) {
      console.log("Error getOrderStagesUsingGET ]", err);
      throw err;
    }
  };
  /**
   *
   * @summary Api to get order summary
   * @param request - request
   */
  public getOrderSummaryUsingPOST = async (
    request: RocketbuyGetOrderSummaryApiRequest,
    customHeaders: Object = {}
  ) => {
    try {
      const path = "/secure/rb/customer/order/summary".replace(/^\//, "");
      const allQueryParams: TQueryParam[] = [];
      const queryParams = allQueryParams
        .map((param) => `${param.key}=${param.value}`)
        .join("&");

      const url = queryParams === "" ? path : path.concat(`?${queryParams}`);
      let headers = customHeaders;
      const response =
        await this.instance.post<RocketbuyGetOrderSummaryApiResponse>(
          url,
          request,
          {
            headers: { ...headers },
          }
        );

      return response;
    } catch (err) {
      console.log("Error getOrderSummaryUsingPOST ]", err);
      throw err;
    }
  };
  /**
   *
   * @summary Api to get product details
   * @param pid -
   */
  public getProductDetailUsingGET = async (
    pid?: string,
    customHeaders: Object = {}
  ) => {
    try {
      const path = "/secure/rb/customer/product/get".replace(/^\//, "");
      const allQueryParams: TQueryParam[] = [
        {
          key: "pid",
          value: pid,
        },
      ];
      const queryParams = allQueryParams
        .map((param) => `${param.key}=${param.value}`)
        .join("&");

      const url = queryParams === "" ? path : path.concat(`?${queryParams}`);
      let headers = customHeaders;
      const response =
        await this.instance.get<RocketbuyGetProductDetailApiResponse>(url, {
          headers: { ...headers },
        });

      return response;
    } catch (err) {
      console.log("Error getProductDetailUsingGET ]", err);
      throw err;
    }
  };
  /**
   *
   * @summary Api to get all products in store
   * @param cid -
   * @param loadBy -
   * @param offset -
   * @param limit -
   */
  public getProductsUsingGET = async (
    cid?: string,
    loadBy?: string,
    offset?: number,
    limit?: number,
    customHeaders: Object = {}
  ) => {
    try {
      const path = "/secure/rb/customer/products".replace(/^\//, "");
      const allQueryParams: TQueryParam[] = [
        {
          key: "cid",
          value: cid,
        },

        {
          key: "loadBy",
          value: loadBy,
        },

        {
          key: "offset",
          value: offset,
        },

        {
          key: "limit",
          value: limit,
        },
      ];
      const queryParams = allQueryParams
        .map((param) => `${param.key}=${param.value}`)
        .join("&");

      const url = queryParams === "" ? path : path.concat(`?${queryParams}`);
      let headers = customHeaders;
      const response =
        await this.instance.get<RocketbuyGetAllProductApiResponse>(url, {
          headers: { ...headers },
        });

      return response;
    } catch (err) {
      console.log("Error getProductsUsingGET ]", err);
      throw err;
    }
  };
  /**
   *
   * @summary Api to get all stores for customer
   */
  public getStoresUsingGET = async (customHeaders: Object = {}) => {
    try {
      const path = "/secure/rb/customer/stores".replace(/^\//, "");
      const allQueryParams: TQueryParam[] = [];
      const queryParams = allQueryParams
        .map((param) => `${param.key}=${param.value}`)
        .join("&");

      const url = queryParams === "" ? path : path.concat(`?${queryParams}`);
      let headers = customHeaders;
      const response = await this.instance.get<GetStoresApiResponse>(url, {
        headers: { ...headers },
      });

      return response;
    } catch (err) {
      console.log("Error getStoresUsingGET ]", err);
      throw err;
    }
  };
  /**
   *
   * @summary Api to create new customer account
   * @param request - request
   */
  public initiateSignupUsingPOST1 = async (
    request: RocketbuyCustomerSignupApiRequest,
    customHeaders: Object = {}
  ) => {
    try {
      const path = "/secure/rb/customer/signup".replace(/^\//, "");
      const allQueryParams: TQueryParam[] = [];
      const queryParams = allQueryParams
        .map((param) => `${param.key}=${param.value}`)
        .join("&");

      const url = queryParams === "" ? path : path.concat(`?${queryParams}`);
      let headers = customHeaders;
      const response = await this.instance.post<RocketbuySignupResponse>(
        url,
        request,
        {
          headers: { ...headers },
        }
      );

      return response;
    } catch (err) {
      console.log("Error initiateSignupUsingPOST1 ]", err);
      throw err;
    }
  };
  /**
   *
   * @summary Api to create new customer account
   */
  public logoutUsingGET2 = async (customHeaders: Object = {}) => {
    try {
      const path = "/secure/rb/customer/logout".replace(/^\//, "");
      const allQueryParams: TQueryParam[] = [];
      const queryParams = allQueryParams
        .map((param) => `${param.key}=${param.value}`)
        .join("&");

      const url = queryParams === "" ? path : path.concat(`?${queryParams}`);
      let headers = customHeaders;
      const response = await this.instance.get<RocketflowBaseResponse>(url, {
        headers: { ...headers },
      });

      return response;
    } catch (err) {
      console.log("Error logoutUsingGET2 ]", err);
      throw err;
    }
  };
  /**
   *
   * @summary Api to get order detail
   * @param orderId -
   */
  public orderDetailsUsingGET = async (
    orderId?: string,
    customHeaders: Object = {}
  ) => {
    try {
      const path = "/secure/rb/customer/order/detail".replace(/^\//, "");
      const allQueryParams: TQueryParam[] = [
        {
          key: "orderId",
          value: orderId,
        },
      ];
      const queryParams = allQueryParams
        .map((param) => `${param.key}=${param.value}`)
        .join("&");

      const url = queryParams === "" ? path : path.concat(`?${queryParams}`);
      let headers = customHeaders;
      const response = await this.instance.get<RocketbuyOrderDetailApiResponse>(
        url,
        {
          headers: { ...headers },
        }
      );

      return response;
    } catch (err) {
      console.log("Error orderDetailsUsingGET ]", err);
      throw err;
    }
  };
  /**
   *
   * @summary Api to reset password
   * @param request - request
   */
  public resetPasswordUsingPOST2 = async (
    request: RocketbuyResetPasswordApiRequest,
    customHeaders: Object = {}
  ) => {
    try {
      const path = "/secure/rb/customer/resetPassword".replace(/^\//, "");
      const allQueryParams: TQueryParam[] = [];
      const queryParams = allQueryParams
        .map((param) => `${param.key}=${param.value}`)
        .join("&");

      const url = queryParams === "" ? path : path.concat(`?${queryParams}`);
      let headers = customHeaders;
      const response =
        await this.instance.post<RocketbuyResetPasswordApiResponse>(
          url,
          request,
          {
            headers: { ...headers },
          }
        );

      return response;
    } catch (err) {
      console.log("Error resetPasswordUsingPOST2 ]", err);
      throw err;
    }
  };
  /**
   *
   * @summary Api to search products by name
   * @param name -
   * @param loadBy -
   */
  public searchProductUsingGET = async (
    name?: string,
    loadBy?: string,
    customHeaders: Object = {}
  ) => {
    try {
      const path = "/secure/rb/customer/product/search".replace(/^\//, "");
      const allQueryParams: TQueryParam[] = [
        {
          key: "name",
          value: name,
        },

        {
          key: "loadBy",
          value: loadBy,
        },
      ];
      const queryParams = allQueryParams
        .map((param) => `${param.key}=${param.value}`)
        .join("&");

      const url = queryParams === "" ? path : path.concat(`?${queryParams}`);
      let headers = customHeaders;
      const response =
        await this.instance.get<RocketbuyGetAllProductApiResponse>(url, {
          headers: { ...headers },
        });

      return response;
    } catch (err) {
      console.log("Error searchProductUsingGET ]", err);
      throw err;
    }
  };
  /**
   *
   * @summary Rocketbuy : Api to Send OTP to given mobile.
   * @param request - request
   */
  public sendOtpUsingPOST2 = async (
    request: RocketbuySendOtpApiRequest,
    customHeaders: Object = {}
  ) => {
    try {
      const path = "/secure/rb/customer/sendOtp".replace(/^\//, "");
      const allQueryParams: TQueryParam[] = [];
      const queryParams = allQueryParams
        .map((param) => `${param.key}=${param.value}`)
        .join("&");

      const url = queryParams === "" ? path : path.concat(`?${queryParams}`);
      let headers = customHeaders;
      const response = await this.instance.post<RocketbuySendOtpApiResponse>(
        url,
        request,
        {
          headers: { ...headers },
        }
      );

      return response;
    } catch (err) {
      console.log("Error sendOtpUsingPOST2 ]", err);
      throw err;
    }
  };
  /**
   *
   * @summary Rocketbuy : Api to get store key.
   * @param mcode -
   * @param domain -
   */
  public storeKeyUsingGET = async (
    mcode?: string,
    domain?: string,
    customHeaders: Object = {}
  ) => {
    try {
      const path = "/secure/rb/customer/storeKey".replace(/^\//, "");
      const allQueryParams: TQueryParam[] = [
        {
          key: "mcode",
          value: mcode,
        },
        {
          key: "domain",
          value: domain,
        },
      ];
      const queryParams = allQueryParams
        .map((param) => `${param.key}=${param.value}`)
        .join("&");

      const url = queryParams === "" ? path : path.concat(`?${queryParams}`);
      let headers = customHeaders;
      const response = await this.instance.get<RocketbuyStoreKeyResponse>(url, {
        headers: { ...headers },
      });

      return response;
    } catch (err) {
      console.log("Error storeKeyUsingGET ]", err);
      throw err;
    }
  };

  public storeKeyUsingDomain = async (
    domain?: string,
    customHeaders: Object = {}
  ) => {
    try {
      const path = "/secure/rb/customer/storeKey".replace(/^\//, "");
      const allQueryParams: TQueryParam[] = [
        {
          key: "domain",
          value: domain,
        },
      ];
      const queryParams = allQueryParams
        .map((param) => `${param.key}=${param.value}`)
        .join("&");

      const url = queryParams === "" ? path : path.concat(`?${queryParams}`);
      let headers = customHeaders;
      const response = await this.instance.get<RocketbuyStoreKeyResponse>(url, {
        headers: { ...headers },
      });

      return response;
    } catch (err) {
      console.log("Error storeKeyUsingDomain ]", err);
      throw err;
    }
  };
  /**
   *
   * @summary Api to update user address
   * @param request - request
   */
  public updateAddressUsingPUT = async (
    request: UserUpdateAddressApiRequest,
    customHeaders: Object = {}
  ) => {
    try {
      const path = "/secure/rb/customer/address/update".replace(/^\//, "");
      const allQueryParams: TQueryParam[] = [];
      const queryParams = allQueryParams
        .map((param) => `${param.key}=${param.value}`)
        .join("&");

      const url = queryParams === "" ? path : path.concat(`?${queryParams}`);
      let headers = customHeaders;
      const response = await this.instance.put<RocketflowBaseResponse>(
        url,
        request,
        {
          headers: { ...headers },
        }
      );

      return response;
    } catch (err) {
      console.log("Error updateAddressUsingPUT ]", err);
      throw err;
    }
  };
  /**
   *
   * @summary Api to verify OTP by mobile number
   * @param request - request
   */
  public verifyMobileUsingPOST2 = async (
    request: RocketbuyVerifyMobileApiRequest,
    customHeaders: Object = {}
  ) => {
    try {
      const path = "/secure/rb/customer/verifyMobile".replace(/^\//, "");
      const allQueryParams: TQueryParam[] = [];
      const queryParams = allQueryParams
        .map((param) => `${param.key}=${param.value}`)
        .join("&");

      const url = queryParams === "" ? path : path.concat(`?${queryParams}`);
      let headers = customHeaders;
      const response =
        await this.instance.post<RocketbuyVerifyMobileApiResponse>(
          url,
          request,
          {
            headers: { ...headers },
          }
        );

      return response;
    } catch (err) {
      console.log("Error verifyMobileUsingPOST2 ]", err);
      throw err;
    }
  };

  /**
   *
   * @summary Api to validate coupon Promo
   * @param request - request
   */
  public validateCouponPromoUsingPOST = async (
    request: RocketbuyValidateCouponPromoRequest,
    customHeaders: Object = {}
  ) => {
    try {
      const path = "/secure/rb/store/coupon/validatePromo".replace(/^\//, "");
      const allQueryParams: TQueryParam[] = [];
      const queryParams = allQueryParams
        .map((param) => `${param.key}=${param.value}`)
        .join("&");

      const url = queryParams === "" ? path : path.concat(`?${queryParams}`);
      let headers = customHeaders;
      const response =
        await this.instance.post<RocketbuyValidateCouponPromoResponse>(
          url,
          request,
          {
            headers: { ...headers },
          }
        );

      return response;
    } catch (err) {
      console.log("Error validateCouponPromoUsingPOST ]", err);
      throw err;
    }
  };

  /**
   *
   * @summary Api to process payment callback
   * @param request - request
   */
  public cancelPaymentUsingPOST = async (
    request: CancelTxnApiRequest,
    customHeaders: Object = {}
  ) => {
    try {
      const path = "/secure/rb/cancelPayment".replace(/^\//, "");
      const allQueryParams: TQueryParam[] = [];
      const queryParams = allQueryParams
        .map((param) => `${param.key}=${param.value}`)
        .join("&");

      const url = queryParams === "" ? path : path.concat(`?${queryParams}`);
      let headers = customHeaders;
      const response = await this.instance.post<RocketflowBaseResponse>(
        url,
        request,
        {
          headers: { ...headers },
        }
      );

      return response;
    } catch (err) {
      console.log("Error cancelPaymentUsingPOST ]", err);
      throw err;
    }
  };
  /**
   *
   * @summary Api get payment link info
   * @param linkId - linkId
   */
  public payNowUsingGET = async (
    linkId: string,
    customHeaders: Object = {}
  ) => {
    try {
      const path = "/secure/rb/payLink/{linkId}"
        .replace(/^\//, "")
        .replace("{" + "linkId" + "}", String(linkId));
      const allQueryParams: TQueryParam[] = [];
      const queryParams = allQueryParams
        .map((param) => `${param.key}=${param.value}`)
        .join("&");

      const url = queryParams === "" ? path : path.concat(`?${queryParams}`);
      let headers = customHeaders;
      const response = await this.instance.get<RocketbuyInitPayResponse>(url, {
        headers: { ...headers },
      });

      return response;
    } catch (err) {
      console.log("Error payNowUsingGET ]", err);
      throw err;
    }
  };
  /**
   *
   * @summary Api to process payment callback
   * @param request - request
   */
  public processPaymentUsingPOST = async (
    request: RocketbuyProcessPayRequest,
    customHeaders: Object = {}
  ) => {
    try {
      const path = "/secure/rb/processPayment".replace(/^\//, "");
      const allQueryParams: TQueryParam[] = [];
      const queryParams = allQueryParams
        .map((param) => `${param.key}=${param.value}`)
        .join("&");

      const url = queryParams === "" ? path : path.concat(`?${queryParams}`);
      let headers = customHeaders;
      const response = await this.instance.post<RocketbuyProcessPayResponse>(
        url,
        request,
        {
          headers: { ...headers },
        }
      );

      return response;
    } catch (err) {
      console.log("Error processPaymentUsingPOST ]", err);
      throw err;
    }
  };
  /**
   *
   * @summary Api get send payment link to customer
   * @param orderId - orderId
   */
  public sendPaymentLinkUsingGET = async (
    orderId: string,
    customHeaders: Object = {}
  ) => {
    try {
      const path = "/secure/rb/sendPayLink/{orderId}"
        .replace(/^\//, "")
        .replace("{" + "orderId" + "}", String(orderId));
      const allQueryParams: TQueryParam[] = [];
      const queryParams = allQueryParams
        .map((param) => `${param.key}=${param.value}`)
        .join("&");

      const url = queryParams === "" ? path : path.concat(`?${queryParams}`);
      let headers = customHeaders;
      const response = await this.instance.get<RocketbuyInitPayResponse>(url, {
        headers: { ...headers },
      });

      return response;
    } catch (err) {
      console.log("Error sendPaymentLinkUsingGET ]", err);
      throw err;
    }
  };

  /**
   *
   * @summary Api to upload files
   * @param data0Files -
   * @param data0Name -
   */
  public uploadFileUsingPOST = async (
    data0Files: Array<File>,
    data0Name: string,
    customHeaders: Object = {}
  ) => {
    try {
      const path = "/secure/rb/uploadFile".replace(/^\//, "");
      const allQueryParams: TQueryParam[] = [
        // {
        //   key: "data[0].files",
        //   value: data0Files,
        // },
        // {
        //   key: "data[0].name",
        //   value: data0Name,
        // },
      ];
      const queryParams = allQueryParams
        .map((param) => `${param.key}=${param.value}`)
        .join("&");

      const url = queryParams === "" ? path : path.concat(`?${queryParams}`);
      let headers = customHeaders;

      const uploadPayload = new FormData();
      uploadPayload.append("data[0].files", data0Files[0]);
      uploadPayload.append("data[0].name", data0Name);

      const response = await this.instance.post<RocketbuyFileUploadApiResponse>(
        url,
        uploadPayload,
        {
          headers: { ...headers },
        }
      );

      return response;
    } catch (err) {
      console.log("Error uploadFileUsingPOST ]", err);
      throw err;
    }
  };
}

export default new RocketBuyCustomerService();
