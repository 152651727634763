import React, { lazy, useEffect, useState } from "react";
import {
  Route,
  useParams,
  Outlet,
  Routes,
  useNavigate,
} from "react-router-dom";
import {
  RF_CARTITEMS,
  RF_MERCHANT,
  RF_MERCHANT_CODE,
  RF_ORDER,
  RF_SELECTED_STORE,
  RF_USER_INFO,
} from "../../constants/storage.constant";
import PrivacyPolicyPage from "../../pages/privacy-policy-page";
import RefundAndCancellationPage from "../../pages/refund-and-cancellation-page";
import SelectStorePage from "../../pages/select-store-page";
import TermsAndConditionsPage from "../../pages/terms-and-conditions-page";
import NonPremiumRoutes from "../../routes/non-premium-routes";
import PremiumRoutes from "../../routes/premium-routes";
import rocketbuyCustomerService from "../../services/rocketbuy.customer.service";
import { isPremiumCustomer, routePath } from "../../utils/route.utils";
import {
  getMarchantCode,
  getMarchantStoreKey,
  parseMerchant,
} from "../../utils/store.utils";
import Loader from "../common/loader/loader";
import Modal from "../common/modal/Modal";
import { SelectStorePopup } from "../common/select-store-popup";
import { useCart } from "../context/cart-context";
import { useApp } from "../context/app-context";
import { useHome } from "../context/home-context";

// const Store = lazy(() => import("./store"));
const StoreClosed = lazy(() => import("./store-closed"));
const AddAddressPage = lazy(() => import("../../pages/add-address"));
const ThankYouPopUp = lazy(
  () => import("../../components/common/thankyou-popup/thankyou-popup")
);
const HomePage = lazy(() => import("../../pages/home-page"));
const HomeStoreListView = lazy(
  () => import("../home-store-list/home-store-list")
);
const CategoriesPage = lazy(() => import("../../pages/categories-page"));
const CheckOutDetailPage = lazy(
  () => import("../../pages/check-out-details-page")
);
const WebCartPage = lazy(() => import("../../pages/web-cart-page"));
const WebAccountPage = lazy(() => import("../../pages/web-account"));
const WebCheckout = lazy(() => import("../../pages/web-checkout"));
const WebDeliveryAddress = lazy(
  () => import("../../pages/web-delivery-address")
);

const ProductListPage = lazy(() => import("../../pages/product-list-page"));
const ProductDetailPage = lazy(() => import("../../pages/product-detail-page"));

const LoginPage = lazy(() => import("../../pages/login-page"));
const SignUpPage = lazy(() => import("../../pages/signup-page"));
const OrdersPage = lazy(() => import("../../pages/orders-page"));
const OrderDetailPage = lazy(() => import("../../pages/order-detail-page"));
const OrderSummaryPage = lazy(() => import("../../pages/order-summary-page"));

const MobileVerificationPage = lazy(
  () => import("../../pages/mobile-verification-page")
);

const AddressPage = lazy(() => import("../../pages/address-page"));

const CartPage = lazy(() => import("../../pages/cart-page"));
const ProfilePage = lazy(() => import("../../pages/profile-page"));
// this for premium client local testing testing ...
const localDevTestingClient =
  process.env.NODE_ENV != "production" ? "https://www.hcmeds.in" : "";

const App: React.FC = () => {
  const { merchantCode = "" } = useParams();
  const {
    displayModal,
    closeModal,
    modalView,
    setModalView,
    openModal,
    setStoreModalView,
  } = useApp();
  const { store } = useHome();
  const { removeCartItems } = useCart();
  const navigate = useNavigate();

  useEffect(() => {
    if (isPremiumCustomer()) {
      // is premium on then only call this function
      const run = async () => {
        const storeResponse =
          await rocketbuyCustomerService.storeKeyUsingDomain(
            localDevTestingClient || window.origin
          );
        console.log(`storeResponse: `, storeResponse);
        if (storeResponse.successful) {
          const merchant = parseMerchant(storeResponse);
          localStorage.setItem(RF_MERCHANT, JSON.stringify(merchant));
          const savedMerchant = getMarchantCode();
          if (savedMerchant !== getMarchantStoreKey()) {
            //empty cart items
            //localStorage.setItem(RF_CARTITEMS, "[]");
            removeCartItems();
            localStorage.removeItem(RF_ORDER);
            localStorage.removeItem(RF_USER_INFO);
          }

          if (storeResponse.multiMerchant) {
            // when multistore then save selected store
            // localStorage.setItem(
            //   RF_SELECTED_STORE,
            //   JSON.stringify(merchant.stores[0]) // store very first element of mutli store
            // );
          }

          navigate(`/home`, { replace: true });

          //setLoading(false);
          //setMultiMerchant(
          //storeResponse.multiMerchant ? storeResponse.multiMerchant : false
          //);
        } else {
          //setLoading(false);
          navigate("/not-found"); // go to close store.
        }
      };
      run();
    }
  }, []);

  console.log(isPremiumCustomer());

  return (
    <React.Fragment>
      <div
        className={
          store.status == null || store.status === "CLOSED"
            ? "filter grayscale"
            : " "
        }
      >
        {isPremiumCustomer() ? <PremiumRoutes /> : <NonPremiumRoutes />}
        <Modal open={displayModal} onClose={closeModal}>
          {modalView === "ADD_ADDRESS_VIEW" && <AddAddressPage />}
          {modalView === "THANK_YOU_VIEW" && <ThankYouPopUp />}
          {modalView === "STORE_LIST_VIEW" && <HomeStoreListView />}
        </Modal>
      </div>
    </React.Fragment>
  );
};

// not in use or premium customer
// function InitMerchant() {
//   const { merchantCode } = useParams();

//   const navigate = useNavigate();
//   const [loading, setLoading] = useState(false);
//   const [multiMerchant, setMultiMerchant] = useState(false);

//   useEffect(() => {
//     const run = async () => {
//       try {
//         setLoading(true);
//         const storeResponse = await rocketbuyCustomerService.storeKeyUsingGET(
//           merchantCode,
//           ""
//         );

//         if (storeResponse.successful) {
//           localStorage.setItem(
//             RF_MERCHANT,
//             JSON.stringify(parseMerchant(storeResponse))
//           );
//           const savedMerchant = getMarchantCode();
//           if (savedMerchant !== merchantCode) {
//             //empty cart items
//             localStorage.setItem(RF_CARTITEMS, "[]");
//             localStorage.removeItem(RF_ORDER);
//             localStorage.removeItem(RF_USER_INFO);
//           }
//           localStorage.setItem(RF_MERCHANT_CODE, merchantCode);
//           if (!storeResponse.multiMerchant) {
//             navigate(`/${merchantCode}/home`, { replace: true });
//           }
//           setLoading(false);
//           //setMultiMerchant(
//           //storeResponse.multiMerchant ? storeResponse.multiMerchant : false
//           //);
//         } else {
//           setLoading(false);
//           navigate("/"); // go to close store.
//         }
//       } catch (error) {
//         setLoading(false);
//         navigate("/"); // go to close store.
//       }
//     };

//     run();
//   }, [merchantCode]);

//   if (loading) {
//     return (
//       <div className="mt-72">
//         <Loader size={"h-20 w-20"} />
//       </div>
//     );
//   }

//   //if (multiMerchant) {
//   //   return <SelectStorePopup showModal />;
//   // }
//   return <Outlet />;
// }

export default App;
