import * as React from "react";
import { useEffect } from "react";
import { NextScreenEnum } from "../../models/NextScreenEnum";
import rocketBuyCustomerService from "../../services/rocketbuy.customer.service";
import { useLocalStorage } from "react-use";
import { useNavigate } from "react-router-dom";

import { RocketbuyCustomerSignupApiRequest } from "../../models/RocketbuyCustomerSignupApiRequest";

import { UserProfileInfo } from "../../models/UserProfileInfo";
import { useApp } from "./app-context";
import { UserAddressInfo } from "../../models/UserAddressInfo";
import { getMarchantCode } from "../../utils/store.utils";
import { RF_ORDER, RF_USER_INFO } from "../../constants/storage.constant";
import { useCart } from "./cart-context";
import { routePath } from "../../utils/route.utils";

type UserInfo = {
  accessId: string;
  loginToken: string;
  userId: string;
  verificationId: string;
  mobile: string;
  nextScreen: NextScreenEnum;
  profileDetail?: UserProfileInfo;
};

const defaultUserInfo = {
  accessId: "",
  loginToken: "",
  userId: "",
  verificationId: "",
  mobile: "",
  nextScreen: NextScreenEnum.LOGIN,
};

type UserContextState = {
  loading: boolean;
  userInfo: UserInfo;
  localUserInfo?: UserInfo;
  error: string;
  nextScreen: NextScreenEnum;
  addresses: UserAddressInfo[];
  placeIdToBeUpdated?: string;
  setUserInfo: (userInfo: UserInfo) => void;
  setAddresses: (addresses: UserAddressInfo[]) => void;
  loginByMobile: (mobileNumber: string, isRoute?: boolean) => void;
  verifyMobile: (mobileNumber: string, otp: string, isWeb?: boolean) => void;
  createNewCustomer: (
    request: RocketbuyCustomerSignupApiRequest,
    isWeb?: boolean
  ) => void;
  setUpdatePlaceId: (placeId: string) => void;
  logout: () => void;
};

type UserAction =
  | {
      type: "USER_LOGIN_REQUEST";
    }
  | {
      type: "USER_LOGIN_SUCCESS";
      payload: UserInfo;
    }
  | {
      type: "USER_LOGIN_FAIL";
      payload: any;
    }
  | {
      type: "USER_NEXT_SCREEN";
      payload: NextScreenEnum;
    }
  | { type: "USER_VERIFICATION_ID"; payload: string }
  | { type: "UPADTE_PLACE_ID"; payload: string }
  | { type: "USER_ADDRESSES"; payload: UserAddressInfo[] };

type UserProviderProps = { children: React.ReactNode };

const initialUserState: UserContextState = {
  loading: false,
  nextScreen: NextScreenEnum.LOGIN,
  userInfo: defaultUserInfo,
  addresses: [],
  setUserInfo: (userInfo: UserInfo) => {},
  setAddresses: (addresses: UserAddressInfo[]) => {},
  loginByMobile: (mobileNumber: string, isRoute?: boolean) => {},
  verifyMobile: (mobileNumber: string, otp: string, isWeb?: boolean) => {},
  createNewCustomer: (
    request: RocketbuyCustomerSignupApiRequest,
    isWeb?: boolean
  ) => {},
  logout: () => {},
  setUpdatePlaceId: (placeId: string) => {},
  error: "",
};

const UserStateContext =
  React.createContext<UserContextState>(initialUserState);

UserStateContext.displayName = "UserContext";

function userContextReducer(
  state: UserContextState = initialUserState,
  action: UserAction
) {
  switch (action.type) {
    case "USER_LOGIN_REQUEST": {
      return {
        ...state,
        loading: true,
        useUser: { ...defaultUserInfo },
        error: "",
      };
    }
    case "USER_LOGIN_SUCCESS": {
      return {
        ...state,
        loading: false,
        userInfo: action.payload,
        error: "",
      };
    }
    case "USER_LOGIN_FAIL": {
      return {
        ...state,
        loading: false,
        error: action.payload,
        userInfo: { ...defaultUserInfo },
      };
    }
    case "USER_NEXT_SCREEN": {
      return {
        ...state,
        loading: false,
        nextScreen: action.payload,
        userInfo: { ...state.userInfo },
        error: "",
      };
    }
    case "USER_VERIFICATION_ID": {
      return {
        ...state,
        verificationId: action.payload,
        userInfo: { ...state.userInfo },
        error: "",
      };
    }

    case "USER_ADDRESSES": {
      return {
        ...state,
        addresses: [...action.payload],
      };
    }
    case "UPADTE_PLACE_ID": {
      return {
        ...state,
        placeIdToBeUpdated: action.payload,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action}`);
    }
  }
}

function UserContextProvider({ children }: UserProviderProps) {
  const navigate = useNavigate();
  const { loginActionFrom } = useApp();
  const { deliveryOption } = useCart();
  const [localUserInfo, setLocalUserInfo, remove] =
    useLocalStorage<UserInfo>(RF_USER_INFO);
  const [state, dispatch] = React.useReducer(
    userContextReducer,
    initialUserState
  );

  useEffect(() => {
    if (localUserInfo) {
      dispatch({
        type: "USER_LOGIN_SUCCESS",
        payload: localUserInfo,
      });
      dispatch({
        type: "USER_NEXT_SCREEN",
        payload: localUserInfo.nextScreen,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loginByMobile = async (
    mobileNumber: string,
    isRoute: boolean = true
  ) => {
    const merchantCode = getMarchantCode();
    try {
      dispatch({ type: "USER_LOGIN_REQUEST" });
      const result = await rocketBuyCustomerService.sendOtpUsingPOST2({
        actionType: NextScreenEnum.LOGIN,
        mobile: mobileNumber,
      });
      if (result.successful) {
        dispatch({
          type: "USER_LOGIN_SUCCESS",
          payload: {
            ...state.userInfo,
            ...result,
          },
        });
        dispatch({
          type: "USER_NEXT_SCREEN",
          payload: result.nextScreen,
        });
        setLocalUserInfo({
          ...state.userInfo,
          ...result,
        });
        if (isRoute) {
          //navigate(`/${result.nextScreen.toLowerCase()}`);
          navigate(
            routePath(merchantCode, `/${result.nextScreen.toLowerCase()}`)
          );
        }
      }
    } catch (error) {
      console.log(`error LoginByMobile`, error);
      // dispatch({
      //   type: "USER_LOGIN_FAIL",
      //   payload:
      //     error.response && error.response.data.message
      //       ? error.response.data.message
      //       : error.message,
      // });
    }
  };

  const verifyMobile = async (
    mobileNumber: string,
    otp: string,
    isWeb: boolean = false
  ) => {
    const merchantCode = getMarchantCode();
    try {
      //      dispatch({ type: "USER_LOGIN_REQUEST" });
      const result = await rocketBuyCustomerService.verifyMobileUsingPOST2({
        mobile: mobileNumber,
        otp,
      });
      if (result.successful) {
        dispatch({
          type: "USER_LOGIN_SUCCESS",
          payload: {
            ...state.userInfo,
            ...result,
          },
        });

        dispatch({
          type: "USER_NEXT_SCREEN",
          payload: result.nextScreen,
        });

        dispatch({
          type: "USER_VERIFICATION_ID",
          payload: result.verificationId,
        });
        setLocalUserInfo({
          ...state.userInfo,
          ...result,
        });
        if (result.profileDetail) {
          if (isWeb) {
            if (loginActionFrom === "MORE-LINK") {
              navigate(routePath(merchantCode, `/web-account`));
            } else {
              navigate(routePath(merchantCode, `/web-delivery-address`));
            }
          } else {
            // already registered user send him next screen as per place oreder flow or More link flow
            if (loginActionFrom === "MORE-LINK") {
              navigate(routePath(merchantCode, `/profile`));
            } else {
              if (deliveryOption === "DELIVERY") {
                navigate(routePath(merchantCode, `/address`));
              } else {
                navigate(routePath(merchantCode, `/order-summary`));
              }
            }
          }
        } else {
          if (isWeb) {
            navigate(routePath(merchantCode, `/web-checkout`));
          } else {
            // new user send him checkout details page screen
            if (loginActionFrom === "MORE-LINK") {
              navigate(routePath(merchantCode, `/signup`));
            } else {
              navigate(routePath(merchantCode, `/checkout-detail`));
            }
          }
        }
        // if user is coming from my cart then send it to checkout form page.
        // if user is coming from More action then  send to register page
        // history.push(result.nextScreen.toLowerCase());
      } else {
        dispatch({
          type: "USER_LOGIN_FAIL",
          payload: result.responseMsg,
        });
      }
    } catch (error) {
      console.log(`error VerifyMobile`, error);
      // dispatch({
      //   type: "USER_LOGIN_FAIL",
      //   payload:
      //     error.response && error.response.data.message
      //       ? error.response.data.message
      //       : error.message,
      // });
    }
  };

  const createNewCustomer = async (
    request: RocketbuyCustomerSignupApiRequest,
    isWeb: boolean = false
  ) => {
    const merchantCode = getMarchantCode();
    try {
      const result = await rocketBuyCustomerService.initiateSignupUsingPOST1(
        request
      );
      if (result) {
        if (result.successful) {
          dispatch({
            type: "USER_LOGIN_SUCCESS",
            payload: {
              ...state.userInfo,
              ...result,
              profileDetail: {
                name: request.name,
                mobile: request.mobile,
                email: request.email || "",
                profileImg: "",
              },
            },
          });

          dispatch({
            type: "USER_NEXT_SCREEN",
            payload: NextScreenEnum.MYPROFILE,
          });

          setLocalUserInfo({
            ...state.userInfo,
            ...result,
          });
          if (isWeb) {
            navigate(routePath(merchantCode, `/web-delivery-address`));
          } else {
            if (loginActionFrom === "MORE-LINK") {
              navigate(routePath(merchantCode, `/home`));
            } else {
              // skip to redirect on address if delivery chosen : PICKUP
              if (deliveryOption === "DELIVERY") {
                navigate(routePath(merchantCode, `/address`));
              } else {
                navigate(routePath(merchantCode, `/order-summary`));
              }
            }
          }
        } else {
          dispatch({
            type: "USER_LOGIN_FAIL",
            payload: result.responseMsg,
          });
        }
      }
    } catch (error) {
      console.log(`error Init Signup`, error);
      // dispatch({
      //   type: "USER_LOGIN_FAIL",
      //   payload:
      //     error.response && error.response.data.message
      //       ? error.response.data.message
      //       : error.message,
      // });
    }
  };

  const logout = async () => {
    const merchantCode = getMarchantCode();
    remove();
    localStorage.removeItem(RF_ORDER);
    dispatch({
      type: "USER_LOGIN_SUCCESS",
      payload: defaultUserInfo,
    });
    navigate(routePath(merchantCode, `/login`));
  };

  const setAddresses = (addresses: UserAddressInfo[]) =>
    dispatch({ type: "USER_ADDRESSES", payload: addresses });
  const setUpdatePlaceId = (placeId: string) =>
    dispatch({ type: "UPADTE_PLACE_ID", payload: placeId });

  const setUserInfo = (userInfo: UserInfo) => {
    dispatch({
      type: "USER_LOGIN_SUCCESS",
      payload: {
        ...state.userInfo,
        ...userInfo,
      },
    });
    setLocalUserInfo(userInfo);
  };

  const values = React.useMemo(
    () => ({
      ...state,
      setAddresses,
      loginByMobile,
      verifyMobile,
      createNewCustomer,
      logout,
      setUpdatePlaceId,
      setUserInfo,
      localUserInfo,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  );

  return (
    <UserStateContext.Provider value={{ ...values }}>
      {children}
    </UserStateContext.Provider>
  );
}

function useUser() {
  const context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUser must be used within a OrderContextProvider");
  }
  return context;
}
export { UserContextProvider, useUser };
