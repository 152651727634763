import * as React from "react";
//import { useEffect } from "react";
import { ProductCategoryInfo } from "../../models/ProductCategoryInfo";
import { RocketbuyGetHomeDataResponse } from "../../models/RocketbuyGetHomeDataResponse";
//import rocketBuyCustomerService from "../../services/rocketbuy.customer.service";
import { fromNullable, getOrElse, none, Option } from "fp-ts/lib/Option";

type RocketStore = {
  categories: Array<ProductCategoryInfo>;
  loginToken: string;
  status: string;
  storeAddress: string;
  storeName: string;
  storeUrl: string;
  timing: string;
  mobileNumber: string;
  premium: boolean;
  minOrderAmt: number;
};

type HomeContextState = {
  loading: boolean;
  store: RocketStore;
  error?: any;
  selectedCategoryId: string;
  selectedCategoryName: Option<string>;
  selectCategory: (categoryId: string) => void;
  loadHomeRequest: () => void;
  loadHomeFail: (error: any) => void;
  loadHomeSuccess: (store: RocketbuyGetHomeDataResponse) => void;
};

type HomeAction =
  | {
      type: "HOME_REQUEST";
      payload: boolean;
    }
  | {
      type: "HOME_SUCCESS";
      payload: RocketStore;
    }
  | {
      type: "HOME_FAIL";
      payload: any;
    }
  | {
      type: "SELECTED_CATEGORY";
      payload: string;
    };

type HomeProviderProps = { children: React.ReactNode };

const initialHomeState: HomeContextState = {
  loading: false,
  store: {
    categories: [],
    loginToken: "",
    status: "",
    storeAddress: "",
    storeName: "",
    storeUrl: "",
    timing: "",
    mobileNumber: "",
    premium: false,
    minOrderAmt: 0,
  },
  selectedCategoryId: "",
  selectedCategoryName: none,

  selectCategory: (categoryId: string) => {},
  loadHomeRequest: () => {},
  loadHomeFail: (error: any) => {},
  loadHomeSuccess: (store: RocketbuyGetHomeDataResponse) => {},
};

const HomeStateContext =
  React.createContext<HomeContextState>(initialHomeState);

HomeStateContext.displayName = "HomeContext";

function homeContextReducer(
  state: HomeContextState = initialHomeState,
  action: HomeAction
) {
  switch (action.type) {
    case "HOME_REQUEST": {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case "HOME_SUCCESS": {
      return {
        ...state,
        loading: false,
        store: action.payload,
      };
    }
    case "HOME_FAIL": {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case "SELECTED_CATEGORY": {
      return {
        ...state,
        selectedCategoryId: action.payload,
        selectedCategoryName: fromNullable(action.payload),
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action}`);
    }
  }
}

const HomeContextProvider: React.FC<HomeProviderProps> = ({
  children,
}: HomeProviderProps) => {
  const [state, dispatch] = React.useReducer(
    homeContextReducer,
    initialHomeState
  );

  // useEffect(() => {
  //   const run = async () => {
  //     try {
  //       dispatch({ type: "HOME_REQUEST", payload: true });
  //       const result = await rocketBuyCustomerService.getHomeDataUsingGET();
  //       dispatch({
  //         type: "HOME_SUCCESS",
  //         payload: {
  //           categories: result.categories,
  //           loginToken: result.loginToken,
  //           status: result.status,
  //           storeAddress: result.storeAddress,
  //           storeName: result.storeName,
  //           storeUrl: result.storeUrl,
  //           timing: result.timing,
  //         },
  //       });
  //       selectCategory(result.categories[0].cid);
  //     } catch (error) {
  //       dispatch({ type: "HOME_FAIL", payload: error });
  //     }
  //   };
  //   run();
  // }, []);

  const selectCategory = (categoryId: string) =>
    dispatch({ type: "SELECTED_CATEGORY", payload: categoryId });

  const loadHomeRequest = () =>
    dispatch({ type: "HOME_REQUEST", payload: true });

  const loadHomeFail = (error: any) =>
    dispatch({ type: "HOME_FAIL", payload: error });

  const loadHomeSuccess = (store: RocketbuyGetHomeDataResponse) =>
    dispatch({
      type: "HOME_SUCCESS",
      payload: {
        categories: store.categories || [],
        loginToken: store.loginToken,
        status: store.status,
        storeAddress: store.storeAddress,
        storeName: store.storeName,
        storeUrl: store.storeLogo,
        timing: store.timing,
        mobileNumber: store.mobile,
        premium: store.premium,
        minOrderAmt: store.minOrderAmt,
      },
    });

  const selectedCategoryName: Option<string> = fromNullable(
    state.store.categories.find((ct) => ct.cid === state.selectedCategoryId)
      ?.name
  );

  const values = React.useMemo(
    () => ({
      ...state,
      selectCategory,
      loadHomeRequest,
      loadHomeFail,
      loadHomeSuccess,
      selectedCategoryName,
    }),
    [state]
  );
  return (
    <HomeStateContext.Provider value={{ ...values }}>
      {children}
    </HomeStateContext.Provider>
  );
};

const useHome = () => {
  const context = React.useContext(HomeStateContext);

  if (context === undefined) {
    throw new Error("useHome must be used within a HomeContextProvider");
  }
  return context;
};
export { HomeContextProvider, useHome };
