import { fromNullable, getOrElse, Option, map, none, fold } from "fp-ts/Option";
import { flow, pipe } from "fp-ts/lib/function";
import { right, left, Either, isRight } from "fp-ts/Either";
import {
  RF_MERCHANT,
  RF_MERCHANT_CODE,
  RF_SELECTED_STORE,
} from "../constants/storage.constant";
import { IMerchant } from "../models/merchant";
import { RocketbuyStoreKeyResponse } from "../models/RocketbuyStoreKeyResponse";
import { StoreInfo } from "../models/StoreInfo";

export const currentStoreAccessId = localStorage.getItem("storeAccessId") || "";
export const parseMerchant = (to: RocketbuyStoreKeyResponse): IMerchant => {
  return {
    premium: to.premium,
    active: to.active,
    correlationId: to.correlationId,
    loginToken: to.loginToken,
    multiMerchant: to.multiMerchant || false,
    storeKey: to.storeKey,
    stores: to.stores,
  };
};

export const getStoreByMCode = (mcode: string) => {
  const merchant = localStorage.getItem(RF_MERCHANT);
  if (merchant) {
    const merchnatObjct: IMerchant = JSON.parse(merchant);
    return merchnatObjct.stores.find(
      (store) => store.name.toLowerCase() === mcode.toLowerCase().trim()
    );
  } else {
    // window.location.href = "/";
  }
};

export const getStoreKeyFromSingleStore = () => {
  const merchant = localStorage.getItem(RF_MERCHANT);
  if (merchant) {
    const merchnatObjct: IMerchant = JSON.parse(merchant);
    return merchnatObjct.storeKey;
  } else {
    return "";
  }
};

export const getMarchantCode = () => {
  return localStorage.getItem(RF_MERCHANT_CODE) || "";
};

export const getMarchantStoreKey = () => {
  const merchant = localStorage.getItem(RF_MERCHANT_CODE) || "";
  if (merchant) {
    const merchantObj = JSON.parse(merchant) as IMerchant;
    console.log(`merchantObj------>`, merchantObj);
    return merchantObj.storeKey;
  } else {
    return "";
  }
};

export const getStoreKey = () => {
  const storeInfo = getStoreByMCode(getMarchantCode());
  if (storeInfo) {
    return storeInfo.storeKey;
  }
  return "";
};

export const isMultiStore = (): boolean => {
  const merchant = fromNullable(localStorage.getItem(RF_MERCHANT));
  const isMulti = pipe(
    merchant,
    map((merchant) => JSON.parse(merchant) as IMerchant),
    map((imerchant) => imerchant.multiMerchant)
    //map((length) => (length > 0 ? true : false))
  );

  return isMulti._tag === "Some" ? isMulti.value : false;
};
export const getStores = (): Option<StoreInfo[]> => {
  const merchant = fromNullable(localStorage.getItem(RF_MERCHANT));
  return pipe(
    merchant,
    getOrElse(() => "{}"),
    JSON.parse,
    (obj: IMerchant) => obj.stores,
    fromNullable
  );
};

export const getSelectedStore = (): Either<Error, StoreInfo> => {
  const store = localStorage.getItem(RF_SELECTED_STORE);
  return store
    ? pipe(store, JSON.parse, right)
    : left(new Error("store not found"));
};

export const getSelectedStoreKey = (): string => {
  const store = localStorage.getItem(RF_SELECTED_STORE);
  // when selected store not found then get merchant default storeKey
  return store ? JSON.parse(store).storeKey : getStoreKeyFromSingleStore();
};

export const getTrackiAI = (): string => {
  const storeKey = isMultiStore()
    ? getSelectedStoreKey()
    : getStoreKeyFromSingleStore();
  return storeKey;
};
